/******* screen-large.less *******/
/*
brakpoints chosen after font-sizes, areas change at different breakpoints
*/
/******* mixins.less 2013-1-16 *******/
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-access,
.body-mobile {
  display: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
.north-wrapper {
  padding: 0;
}
.content-half {
  float: left;
  width: 31.42857143%;
  margin-top: 100px;
}
.maincontent {
  width: 61.42857143%;
}
.cb-layout2 .navigation {
  /*
  align with content
  */
  top: 104px;
}
.aside {
  float: right;
}
.cb-layout2 #head {
  width: 76.74418605%;
  margin-left: 11.62790698%;
}
.navigation.navigation.navigation {
  position: absolute;
  top: 253px;
}
.main {
  width: 100%;
}
.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.main h2,
.main .foot {
  margin-right: 11.62790698%;
  margin-left: 11.62790698%;
}
.main .part,
.main > .grid table {
  margin-right: 11.62790698%;
  margin-left: 11.62790698%;
  width: 76.74418605%;
}
.main > .slim .part,
.main > .slim.grid table {
  width: 76.74418605%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side h2,
.side .foot {
  margin-right: 22.72727273%;
  margin-left: 22.72727273%;
}
.side .part,
.side > .grid table {
  margin-right: 22.72727273%;
  margin-left: 22.72727273%;
  width: 54.54545455%;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 54.54545455%;
}
.north > .unit.unit.unit {
  width: 33.33333333%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
div.side {
  margin-top: 31px;
}
.north .link > .open {
  min-height: 3.5em;
}
.fold h2 .fold-toggle:after {
  left: 11.62790698%;
}
.aside .fold h2 .fold-toggle:after {
  left: 22.72727273%;
}
.side .unit.pure + .seam {
  /*
  veranstaltung
  */
  margin-top: 75px;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
/*# sourceMappingURL=./screen-large.css.map */